import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon } from '@heroicons/react/20/solid'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const SolutionsPage = ({ active = `solutions` }) => (
  <Layout>
    <Seo title="My Work and Well-Being | Workplace health & well-being" />
    <div className="max-w-[1200px] mx-auto">
      <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
        <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
        <Menu active={active} />
      </div>
    </div>
    <div className="h-60 bg-mylightorange"></div>
    <div className="max-w-[1200px] mx-auto -mt-44 text-center">
      <p className="text-white text-3xl lg:text-4xl pb-4 pl-6 font-tbold text-left">
        Workplace Health and Well-being Services
      </p>
      <StaticImage
        src="../images/image-5.jpg"
        alt="Decorative image of a healthy workplace team"
        className="rounded-2xl m-6 lg:p-0"
      />
    </div>

    <section className="py-12 max-w-[1200px] mx-auto bg-white">
      <div className="md:max-w-4xl px-8 md:mx-auto">
        <h3 className="text-mypurple text-2xl md:text-3xl mb-8">Solutions</h3>
        <p className="text-lg md:text-xl mb-6 text-gray-800 text-left">
          We work with organizational leaders to foster, promote, and maintain a
          Psychologically Healthy Workplace. Some additional areas include
          Employee Financial Well-being, Employee Engagement, Employee
          Communication, Well-being at Work, Stress in the workplace,
          Occupational Health, Culture, Leadership, People Management, Values
          and Principles, and more.
        </p>
        <div>
          <p className="text-left text-mypurple text-lg font-bold">
            An overview:
          </p>
          <ul className="text-xl md:text-2xl lg:basis-1/2 text-left text-gray-600 tracking-tight font-tbold checks">
            <li className="mt-4">
              Workplace Needs Assessment Well-Being Research
            </li>
            <li className="mt-4">
              Employee Well-being and Wellness Strategic Planning
            </li>
            <li className="mt-4">Reduction of Absenteeism and Presenteeism</li>
            <li className="mt-4">Stress Management</li>
            <li className="mt-4">Mental Health at Work</li>
            <li className="mt-4">Anti-Bullying and Harassment at Work</li>
            <li className="mt-4">Diversity and Inclusion Management</li>
            <li className="mt-4">Anti-Sexual Harassment at Work</li>
            <li className="mt-4">Workplace Violence Prevention</li>
            <li className="mt-4">Work-Life-Balance Strategies</li>
            <li className="mt-4">Health and Safety at Work</li>
            <li className="mt-4">
              Customized well-being strategies and services to meet
              organizational needs
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section className="flex flex-nowrap items-center justify-evenly p-6 my-12 bg-mypurple max-w-[1200px] mx-auto">
      <div className="flex items-center mr-6 text-white">
        <Cog6ToothIcon className="h-12 w-12" />{' '}
        <span className="text-2xl lg:text-3xl pl-2">
          Ready to Shift Gears and Take Action?
        </span>
      </div>
      <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
        <Link
          type="button"
          to="/bookcall"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-mypurple shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Book a Call
        </Link>
      </div>
    </section>
    <section className="pt-24 pb-12 my-6 max-w-[1200px] mx-auto bg-slate-50">
      <div className="md:max-w-4xl px-8 md:mx-auto">
        <div className="max-w-[1200px] mx-auto mb-12">
          <StaticImage
            src="../images/image-3.jpg"
            alt="Decorative image showing employee wellness program"
            className="h-full rounded-xl"
          />
        </div>
        <h2 className="text-4xl md:text-5xl mb-12 text-myorange">
          Why Invest in Workplace Well-Being?
        </h2>
        <p className="text-2xl md:text-3xl mb-6 text-gray-800">
          Employees’ health and well-being are embedded in how people interact
          with one another daily at work, it also relates to working conditions,
          management practices, communication, change strategies, and
          performance. The importance of employee health and well-being is
          growing in recognition of the need for employer well-being practices
          to address working life's psychosocial and physical aspects.
        </p>
        <div>
          <p className="text-left text-mypurple text-lg font-bold mt-12">
            An overview:
          </p>
          <ul className="text-lg md:text-xl lg:basis-1/2 text-left text-gray-600 tracking-tight font-tbold checks">
            <li className="mb-4">Employee Well-Being Drives Productivity.</li>
            <li className="mb-4">
              Well-Being is Everything! Every aspect of your employees’ lives
              can influence their productivity at work.
            </li>
            <li className="mb-4">
              Providing a culture of health and well-being and encouraging
              employees through engagement, collaboration, and stress reduction
              results in a more active, healthier, and satisfied workforce.
            </li>
            <li className="mb-4">
              Lost employee productivity costs employers 2-3 times more than
              they pay in annual healthcare expenses.
            </li>
            <li className="mb-4">
              Employees that are less stressed, healthy, happy, and engaged are
              more productive. They are more alert on the job, more successful,
              and safer. They are more present on the job and likely to stay
              with your organization longer.
            </li>
            <li className="mb-4">
              Employees less stressed with positive well-being will lower their
              health risks, resulting in lower health concerns, worker’s
              compompensation claims, absenteeism and presenteeism.
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/g320ycpvCto"
          title="Video"
        ></iframe>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <StaticImage
          src="../images/image-1.jpg"
          alt="Decorative image showing employee wellness program"
          className="h-full"
        />
      </div>
    </section>
  </Layout>
)

export default SolutionsPage
